<template>
  <v-row wrap no-gutters>
    <credentials-select
      :key="`${step.id}-credentialName`"
      step-type="PYTHON"
      :properties="properties"
      step-label="jdbc"
      :readonly="!canEdit"
      :required="false"
      @change="handleChange('credentialName', $event)"
    />

    <libraries-select
      :key="`${step.id}-libraries`"
      step-type="PYTHON"
      step-label="python"
      :properties="properties"
      :readonly="!canEdit"
      @change="handleChange('librariesNames', $event)"
    />

    <v-col cols="12" class="py-1">
      <ExecutionResultPairPythonModal
        :key="`${step.id}-set`"
        step-type="PYTHON"
        type="python"
        :can-edit="canEdit"
        :steps="steps"
        :data="properties.set"
        @dataChanged="handleChange('set', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { CredentialsSelect, LibrariesSelect } from './components'
import ExecutionResultPairPythonModal from '../ExecutionResultPairPythonModal.vue'
import StepMixin from './StepMixin'

export default {
  name: 'PythonStep',
  components: {
    CredentialsSelect,
    LibrariesSelect,
    ExecutionResultPairPythonModal
  },
  mixins: [StepMixin],
  props: {
    steps: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  created() {
    if (!this.properties.set) this.$set(this.properties, 'set', {})
  }
}
</script>
