<template>
  <div v-if="initDone" class="border-class pa-1">
    <v-row no-gutters align="center">
      <v-col cols="6" class="d-inline-flex">
        <h3>{{ $lang.labels[type] }}</h3>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn
          icon
          :outlined="$vuetify.theme.dark"
          small
          class="color-secondary bg-outline-color"
          :disabled="!canEdit"
          @click="deconstructedData.push({ text: '', value: { execution: '', result: '' } }), editRow(deconstructedData.length - 1)"
        >
          +
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="pt-2">
      <v-col v-if="duplicatedKeys" cols="12" class="pb-2">
        <v-alert dense color="error" style="color: black">{{ $lang.errors.duplicatedKeys }}</v-alert>
      </v-col>
      <v-col cols="12">
        <template v-for="(item, i) in deconstructedData">
          <v-card :key="i" flat class="pa-2 mb-1" style="position: relative">
            <div class="d-inline-flex" style="position: absolute; top: 8px; right: 8px">
              <v-btn
                icon
                small
                :disabled="!canEdit"
                @click="editRow(i)"
              >
                <v-icon small color="info">mdi-pen</v-icon>
              </v-btn>
              <v-btn
                class="ml-1"
                icon
                small
                :disabled="!canEdit"
                @click="deleteRow(i)"
              >
                <v-icon small color="error">mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
            <v-row no-gutters align="center" class="pb-1">
              <v-col cols="12">
                <p class="pb-0 mb-0 color-primary">{{ $lang.header.key }}</p>
                <p class="pb-0 mb-0">{{ item.text }}</p>
              </v-col>
              <v-col cols="12" class="my-1">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <p class="pb-0 mb-0 color-primary">{{ $lang.header.execution }}</p>
                <p class="pb-0 mb-0" style="min-height: 24px">{{ item.value.execution.length > 150 ? `${item.value.execution.substring(0, 147)}...` : item.value.execution }}</p>

                <p class="pb-0 mb-0 color-primary">{{ $lang.header.result }}</p>
                <p class="pb-0 mb-0" style="min-height: 24px">{{ item.value.result.length > 150 ? `${item.value.result.substring(0, 147)}...` : item.value.result }}</p>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </v-col>
    </v-row>
    <v-dialog v-if="showPythonEditor" v-model="showPythonEditor" max-width="71%">
      <global-python-editor
        :item="selectedItem"
        :with-buttons="true"
        :can-edit="canEdit"
        :vars="steps"
        :step-type="stepType"
        :single-step="singleStep"
        @closeDialog="closeEdit()"
        @fromGlobalEditor="updateFromEditor($event)"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import globalPythonEditor from '@/components/ui/GlobalPythonEditor.vue'

export default {
  components: { globalPythonEditor },
  props: {
    steps: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Object,
      default: () => {
        return null
      }
    },
    type: {
      type: String,
      default: () => {
        return ''
      }
    },
    stepType: {
      type: String,
      default: () => {
        return ''
      }
    },
    full: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    singleStep: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data: () => ({
    showPythonEditor: false,
    deconstructedData: [],
    selectedItem: null,
    selectedKeys: [],
    duplicatedKeys: false,
    initDone: false
  }),
  computed: {
    ...mapState('app', ['userSettings'])
  },
  watch: {
    deconstructedData: {
      handler() {
        let tempData = null

        if (this.type !== 'switch') {
          tempData = {}

          this.deconstructedData.forEach((item) => {
            if (item.text && item.value) tempData[item.text] = item.value
          })
        }
        this.selectedKeys = this.deconstructedData.map((x) => x.text)
        this.duplicatedKeys = !!this.hasDuplicates(this.selectedKeys)
        this.$emit('dataChanged', tempData)
      },
      deep: true
    }
  },
  created() {
    for (const row in this.data) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.data.hasOwnProperty(row)) {
        this.deconstructedData.push({ text: row , value: this.data[row] })
      }
    }
    this.deconstructedData.forEach((item) => {
      if (item.value && !item.value.execution) item.value.execution = ''
    })
    this.initDone = true
  },
  methods: {
    updateFromEditor(data) {
      this.deconstructedData[this.selectedItemIndex].text = data.key
      this.deconstructedData[this.selectedItemIndex].value = data.value
      this.showPythonEditor = false
      this.selectedItemIndex = null
    },
    hasDuplicates(arr) {
      return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x))
    },
    deleteRow(i) {
      this.deconstructedData.splice(i, 1)
    },
    editRow(i) {
      this.selectedItem = this.deconstructedData[i]
      this.selectedItemIndex = i
      this.showPythonEditor = true
    },
    closeEdit() {
      if (this.selectedItem?.value?.result === '' && this.selectedItem?.text === '') {
        this.deconstructedData.splice(this.selectedItemIndex, 1)
      }
      this.selectedItem = null
      this.selectedItemIndex = null
      this.showPythonEditor = false
    }
  }
}
</script>
<style lang="scss" scoped>
.border-class {
  border: 1px var(--v-customInputBorderColor-base) solid;
  border-radius: 6px;
}
</style>
